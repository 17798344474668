<template>
  <div class="clearfix">
    <a-upload
      :action="UploadLabelImageURL"
      :headers="headers"
      list-type="picture-card"
      v-model:file-list="fileList"
      @preview="handlePreview"
      @change="handleChange"
    >
      <div v-if="fileList.length < 1">
        <plus-outlined />
        <div class="ant-upload-text">Загрузить</div>
      </div>
    </a-upload>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
      <img alt="example" style="width: 100%" :src="previewImage" />
    </a-modal>
  </div>
</template>
<script>
import { PlusOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex';


function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => resolve(reader.result);

    reader.onerror = error => reject(error);
  });
}

export default defineComponent({

  components: {
    PlusOutlined,
  },

  emits: ['actionUploadLabelImage'],

  setup(props, context) {
    const store = useStore()
    const previewVisible = ref(false);
    const previewImage = ref('');
    const fileList = ref([]);

    const handleCancel = () => {
      previewVisible.value = false;
    };

    const handlePreview = async file => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }

      previewImage.value = file.url || file.preview;
      previewVisible.value = true;
    };

    const handleChange = ({ fileList: newFileList }) => {
      fileList.value = newFileList;
      sendActionUploadLabelImageEvent()
    };

    const sendActionUploadLabelImageEvent = () => {
      context.emit('actionUploadLabelImage', fileList)
    };

    const token = computed(() => store.getters['auth/token'])
    const UploadLabelImageURL = computed(() => store.getters['url/UploadLabelImageURL'])

    return { 
      headers: {
         Authorization: 'bearer ' + token.value,
      },
      UploadLabelImageURL,
      previewVisible,
      previewImage,
      fileList,
      handleCancel,
      handlePreview,
      handleChange,
      sendActionUploadLabelImageEvent
    };
  },
});
</script>
<style>
/* you can make up upload button and sample style by using stylesheets */
.ant-upload-select-picture-card i {
  font-size: 32px;
  color: #999;
}

.ant-upload-select-picture-card .ant-upload-text {
  margin-top: 8px;
  color: #666;
}
</style>