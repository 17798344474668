import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'


require('@/store/subscriber')
axios.defaults.baseURL = process.env.VUE_APP_API_URL
const app = createApp(App)

store.dispatch('auth/attempt', localStorage.getItem('token')).then(()=>{
    app.config.productionTip = false;
    app.use(Antd);
    app.use(VueAxios, axios);
    app.use(store);
    app.use(router);
    app.mount('#app')
})