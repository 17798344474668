<template>
  <a-form
    v-if="formVisible"
    ref="formRef"
    :model="formState"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    >
    <a-row 
      type="flex"
      justify="space-between"
      >
      <a-col :flex="16">
        <a-card>
          <a-form-item ref="author" label="Автор: " name="author">
            <a-input v-model:value="formState.author" placeholder="Автор письма (именительный падеж)"/>
          </a-form-item>
          <a-form-item  label="Метка: " name="label_image">
            <UploadLabelImage @actionUploadLabelImage="addLabelImageToForm"/>
          </a-form-item>
          <a-form-item ref="from" label="От кого: " name="from">
            <a-input v-model:value="formState.from" placeholder="От кого"/>
          </a-form-item>
          <a-form-item ref="to" label="Кому: " name="to">
            <a-input v-model:value="formState.to" placeholder="Кому"/>
          </a-form-item>
          <a-form-item ref="year" label="Год: " name="year">
            <a-input v-model:value="formState.year" />
          </a-form-item>
          <a-form-item label="Описание: " name="description">
            <a-textarea v-model:value="formState.description" placeholder="Описание"/>
          </a-form-item>
          <a-form-item  label="Аудио: " name="audio">
            <Audio @actionUploadAudio="addAudioToForm" />
          </a-form-item>
          <a-form-item  label="Изображения: " name="images" style="margin:0">
            <UploadImages @actionUploadImages="addImagesToForm" />
          </a-form-item>
          <a-form-item  label="Адрес: " name="location">
            <VueSuggestions
              v-model:model.sync="dadata.city"
              v-model:kladr_id.sync="dadata.kladrID"
              v-model:location.sync="formState.location"
              :placeholder="'Начните вводить'"
              :class="form-control"
              :options="dadata.suggestionOptions">
            </VueSuggestions>
          </a-form-item>         
          <a-form-item label="" name="user_uuid" style="margin:0">
          </a-form-item>
          <a-form-item  label="" name="city_id" style="margin:0">
          </a-form-item>
        </a-card>
      </a-col>
      <a-col :flex="6">
        <a-card>
            <a-form-item label="Точка интереса: " name="poi">
              <a-switch v-model:checked="formState.poi" />
            </a-form-item>
            <a-form-item label="Опубликовать: " name="verified">
              <a-switch v-model:checked="formState.verified" />
            </a-form-item>
            <a-form-item>
              <a-button type="primary" @click="onSubmit">Создать</a-button>
            </a-form-item>
        </a-card>
      </a-col>  
    </a-row>
    </a-form>
  <a-result 
    v-if="!formVisible"
    status="success"
    title="Письмо успешно создано"
    >
    <template #extra>
      <router-link :to="{name:'Letters'}">
        <a-button type="primary"> Вернуться на главную </a-button>
      </router-link>
    </template>
  </a-result>
</template>

<script>

import { defineComponent, reactive, ref, toRaw, onMounted, computed, watch } from 'vue';
import UploadImages from '@/components/images/UploadImages.vue';
import UploadLabelImage from '@/components/images/UploadLabelImage.vue';
import Audio from '@/components/audio/AudioUpload.vue';
import { notification } from 'ant-design-vue';
import axios from 'axios';
import { useStore } from 'vuex';
import VueSuggestions from 'vue-suggestions';


export default defineComponent({
  components: {
    UploadImages,
    UploadLabelImage,
    Audio,
    VueSuggestions
  },

  setup() {
    const store = useStore();
    const formRef = ref();
    const cities = ref();
    const formVisible = ref(true);
    const dadata = reactive({
      city: '',
      kladrID: 0,
        suggestionOptions: {
          // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454318
          token: '93af0f1a0913a6bdc862d57f1679af5ace06b13b',
          type: "ADDRESS",
          scrollOnFocus: false,
          triggerSelectOnBlur: false,
          triggerSelectOnEnter: true,
          addon: 'clear',
          // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454320
          onSelect (suggestion) {
            
          }
        }
    });
    const formState = reactive({
      author: '',
      label_image: '',
      from: '',
      to: '',
      year: '',
      verified: true,
      description: '',
      audio: '',
      location: {
          lat: '',
          lon: ''
      },
      images: [],
      city_id: '',
      user_uuid: '',
      poi: false,
    });
    const rules = {
      author: [
        {
          required: true,
          message: 'поле не должно быть пустым',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 255,
          message: 'Длинна поля должна быть от 3 до 255 символов',
          trigger: 'blur',
        },
      ],
      from: [
        {
          required: false,
        },
        {
          max: 255,
          message: 'Длинна поля должна быть до 255 символов',
          trigger: 'blur',
        },
      ],
      to: [
        {
          required: true,
          message: 'поле не должно быть пустым',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 255,
          message: 'Длинна поля должна быть от 3 до 255 символов',
          trigger: 'blur',
        },
      ],
      year: [
        {
          required: true,
          message: 'поле не должно быть пустым',
          trigger: 'blur',
        },
        {
          min: 1,
          max: 4,
          message: 'Длинна поля должна быть до 4 символов',
          trigger: 'blur',
        },
      ],    
      description: [
        {
          required: false,
          trigger: 'blur',
        }
      ],
      audio: [
         {
          required: true,
          message: 'поле не должно быть пустым',
          trigger: 'blur',
        },
      ],
      label_image: [
         {
          required: false,
          trigger: 'blur',
        },
      ],
    };

    const onSubmit = () => {
      let result = toRaw(formState)
      formRef.value
        .validate()
        .then(() => {
          if (!result.city_id) {
            notification['error']({
            message: "Город не найден",
            });
          } else {
          axios
            .post('api/letters/create', 
              {
                author: result.author,
                from: result.from,
                to: result.to,
                year: result.year,
                is_verified: result.verified,
                poi: result.poi,
                description: result.description,
                audio: result.audio,
                label_image: result.label_image,
                images: result.images,
                location: result.location,
                city_id: result.city_id,
                user_uuid: result.user_uuid,
              })
              notification['success']({
                message: "Письмо успешно создано",
              });
              changeFormVisible(false)
          }
        })
        .catch(error => {
          console.log('error', error);
        });
    };

    const resetForm = async() => {
      await formRef.value.resetFields()
    };

    const addImagesToForm = (data) => {
      let res = toRaw(data)
      console.log(res)
      let arr = [];
      for (let i = 0; i < res.length; i++) {
        arr.push({
          order:"",
          src:""
        })
      }
      for (let key in res) {
        arr[key].order = key
        arr[key].src = res[key].response.data.name
      }
      formState.images = arr
      console.log(arr)
    } 

    const addAudioToForm = data => {
      let res = toRaw(data.value)
      formState.audio = res[0].response.data.name
    }

    const addLabelImageToForm = data => {
      let res = toRaw(data.value)
      if ( res[0] === undefined) {
        formState.label_image = ''
      } else {
        formState.label_image = res[0].response.data.name 
      }
    }

    const changeFormVisible = (b) => {
      formVisible.value = b
    };

    const clearFormState = () => {
      formState.author = ''
      formState.label_image = ''
      formState.from = ''
      formState.to = ''
      formState.year = ''
      formState.verified = true
      formState.poi = false
      formState.description = ''
      formState.audio = ''
      formState.images = [{
        order:'',
        src: ''
      }],
      formState.location = {
          lat: '',
          lon: ''
      },
      formState.city_id = ''
      setUserUuid()
    }

    const makeAnotherOne = () => {
      changeFormVisible(true)
      clearFormState()
    }

    const getCities = async() => {
        axios.get('api/cities/all').then( response => {
          cities.value = response.data.data.payload
        })
    }

    const getCityByKladrId = kladrID => {
      axios
        .get('api/cities/kladr-id/'+kladrID)
        .then( response => {
          formState.city_id = response.data.data.id
        })
        .catch(error => {
          console.log(error)
          notification['error']({
            message: "Город не найден",
          });
        })
    }

    const setCityId = data => {
      formState.city_id = data
    }

    const user = computed(() => store.getters['auth/user']);

    const setUserUuid = () => {
      formState.user_uuid = user.value.uuid
    }
    
    watch(() => dadata.kladrID, (kladrID) => {
      getCityByKladrId(kladrID);
    });

    onMounted(
      getCities(),
      setUserUuid()
    )

    return {
      formRef,
      cities,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      other: '',
      formState,
      rules,
      onSubmit,
      resetForm,
      addImagesToForm,
      addAudioToForm,
      addLabelImageToForm,
      formVisible,
      makeAnotherOne,
      dadata,
      setCityId
    };
  },

});
</script>
<style lang="scss">
$subtext-color: #777;
$suggestions-bg-color: #fff;
$subtext-label-color: #f5f5f5;

@mixin box-sizing($sizing: border-box){
    -ms-box-sizing: $sizing;
    -moz-box-sizing: $sizing;
    -webkit-box-sizing: $sizing;
    box-sizing: $sizing;
}

@mixin rounded($radius){
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    border-radius: $radius;
}
.ant-form-item-label {
  padding-right:100px
}
.suggestions-nowrap {
    white-space: nowrap;
}

/**
 * Основной INPUT
 */
.suggestions-input {
    // IE9 can't determine border-style until it is specified in stylesheets
    //  border: 1px solid grey;
    @include box-sizing();
    width: 100%;
    &::-ms-clear {
        display: none;
    }
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    padding-left: 11px !important;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}

.suggestions-wrapper {
    position: relative;
    margin: 0;
    padding: 0;
    vertical-align: top;

    //Prevent font scaling in landscape while allowing user zoom
    -webkit-text-size-adjust: 100%;
}

/**
 * Выпадающий блок с найденными подсказками
 */
.suggestions-suggestions {
    background: $suggestions-bg-color;
    border: 1px solid #999;
    @include box-sizing;
    cursor: default;
    left: 0;
    min-width: 100%;
    position: absolute;
    z-index: 9999;

    //Prevent font scaling in landscape while allowing user zoom
    -webkit-text-size-adjust: 100%;

    strong {
        font-weight: normal;
        color: #3399ff;
    }

    &.suggestions-mobile {
        border-style: none;

        .suggestions-suggestion {
            border-bottom: 1px solid #ddd;
        }
    }
}

/**
 * Контейнер для одной подсказки
 */
.suggestions-suggestion {
    padding: 4px 4px;
    overflow: hidden;

    &:hover {
        background: darken($suggestions-bg-color, 3%);
    }
}

/**
 * Выбранная (активная) подсказка
 */
.suggestions-selected {
    background: darken($suggestions-bg-color, 6%);

    &:hover {
        background: darken($suggestions-bg-color, 6%);
    }
}

/**
 * Информационный блок в верхней части выпадашки с подсказками
 */
.suggestions-hint {
    padding: 4px 4px;
    white-space: nowrap;
    overflow: hidden;
    color: $subtext-color;
    font-size: 85%;
    line-height: 20px;
}

/**
 * Дополнительный текст в подсказке, который идет второй строкой
 */
.suggestions-subtext {
    color: $subtext-color;
}

/**
 * Размещает дополнительный текст в одну строку с основным текстом подсказки
 */
.suggestions-subtext_inline {
    display: inline-block;
    min-width: 6em;
    vertical-align: bottom;
    margin: 0 0.5em 0 0;
}

/**
 * Разделитель нескольких дополнительных текстов
 */
.suggestions-subtext-delimiter {
    display: inline-block;
    width: 2px;
}

/**
 * Выделяет подсказку
 */
.suggestions-subtext_label {
    margin: 0 0 0 0.25em;
    @include rounded(3px);
    padding: 0 3px;

    background: $subtext-label-color;
    font-size: 85%;
}

.suggestions-value {
    &[data-suggestion-status="LIQUIDATED"] {
        position: relative;

        &:after {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;

            border-top: 1px solid rgba(0, 0, 0, 0.4);

            content: "";
        }
    }
}

/**
 * Промо-блок
 */
.suggestions-promo {
    font-size: 85%;
    display: none;
    color: $subtext-color;
    padding: 4px;
    text-align: center;
    a {
        color: $subtext-color;
        display: block;
        filter: grayscale(100%);
        line-height: 20px;
        text-decoration: none;
    }
    a:hover {
        filter: grayscale(0);
    }
    svg {
        height: 20px;
        vertical-align: bottom;
    }
}

@media screen and (min-width: 600px) {
    .suggestions-promo {
        position: absolute;
        top: 0;
        right: 0;
        text-align: left;
    }
}

</style>