<template>
  <div id="wave"></div>
  <div id="timeline"></div>
  <div class="controls" style="padding:30px">
    <a-spin v-if="playerLoading" />
    <div v-if="!playerLoading">
      <a-button type="primary" @click="onDelete" v-if="!editBtnVisible">
        <DeleteOutlined />
        Удалить аудио
      </a-button>
      <a-button style="margin:20px" type="primary" @click="onDownload" v-if="!editBtnVisible">
        <CloudDownloadOutlined />
      </a-button>
      <a-button type="primary" @click="onPlay" style="margin-right: 10px;">
        <CaretRightOutlined /> / <PauseOutlined />
      </a-button>
      <a-button type="primary" @click="startEdit" v-if="!editBtnVisible"> Редактировать </a-button>
      <div>
        <a-button type="primary" @click="trim" v-if="editBtnVisible" style="margin:5px">
          <ScissorOutlined />
          Обрезать 
        </a-button>
        <a-button type="primary" @click="deleteChunk" v-if="editBtnVisible" style="margin:5px">
          <DeleteOutlined />
          Вырезать 
        </a-button>
        <a-button type="primary" @click="reload" v-if="editBtnVisible">
          <ReloadOutlined />
          перезагрузить 
        </a-button>
        <br>
        <a-button type="primary" @click="stopEdit" v-if="editBtnVisible">
          <StopOutlined />
          Завершить 
        </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  ref,
  computed,
  onUnmounted,
  watch
} from 'vue';
import WaveSurfer from "wavesurfer.js";
import RegionPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import {
  useStore
} from 'vuex';
import axios from 'axios';
import { notification } from 'ant-design-vue';

import {
  CaretRightOutlined,
  PauseOutlined,
  ScissorOutlined,
  DeleteOutlined,
  StopOutlined,
  ReloadOutlined,
  CloudDownloadOutlined
} from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    CaretRightOutlined,
    PauseOutlined,
    ScissorOutlined,
    DeleteOutlined,
    StopOutlined,
    ReloadOutlined,
    CloudDownloadOutlined
  },

  props: ['link', 'letterNumber'],
  emits: ['actionEditAudio','actionDestroyAudio'],

  setup(props, context) {

    const store = useStore();
    const editBtnVisible = ref(false)
    const playerLoading = ref(true)

    watch(() => props.link, (link) => {
      loadAudio(link);
    });

    const reload = () => {
      getWavesurfer.value.regions.clear();
      editBtnVisible.value = false
      playerLoading.value = true
      loadAudio(props.link).then(playerLoading.value = false)
    }

    const loadAudio = async (link) => {
      return getWavesurfer.value.load(link)
    }

    const getWavesurfer = computed(() => WaveSurfer.create({
      container: '#wave',
      waveColor: '#46a6d8',
      barWidth: 3,
      barGap: 2,
      height: 130,
      cursorWidth: 1,
      cursorColor: "white",
      responsive: 1000,
      normalize: true,
      plugins: [
        RegionPlugin.create({
          regions: [],
          dragSelection: false,
          slop: 10,
        }),
        TimelinePlugin.create({
          container: "#timeline"
        }),
      ],
    }));

    const onPlay = () => {
      getWavesurfer.value.playPause()
    }

    const onDownload = () => {
        const url = props.link;
        window.location.href = url;
    }

    const deleteChunk = () => {
      const start = getWavesurfer.value.regions.list[Object.keys(getWavesurfer.value.regions.list)[0]].start.toFixed(2);
      const end = getWavesurfer.value.regions.list[Object.keys(getWavesurfer.value.regions.list)[0]].end.toFixed(2);
      const originalBuffer = getWavesurfer.value.backend.buffer;
      var emptySegment = getWavesurfer.value.backend.ac.createBuffer(
        originalBuffer.numberOfChannels,
        (getWavesurfer.value.getDuration() - (end - start)) * (originalBuffer.sampleRate * 1),
        originalBuffer.sampleRate
      );

      for (let i = 0; i < originalBuffer.numberOfChannels; i++) {
        let chanData = originalBuffer.getChannelData(i);
        let segmentChanData = emptySegment.getChannelData(i);
        let offset = end * originalBuffer.sampleRate;
        for (let j = 0; j < originalBuffer.length; j++) {
          if (j < (start * originalBuffer.sampleRate)) {
            segmentChanData[j] = chanData[j];
          } else {
            segmentChanData[j] = chanData[offset];
            offset++;
          }
        }
      }
      getWavesurfer.value.loadDecodedBuffer(emptySegment);
    }

    const trim = () => {
      const start = getWavesurfer.value.regions.list[Object.keys(getWavesurfer.value.regions.list)[0]].start.toFixed(2);
      const end = getWavesurfer.value.regions.list[Object.keys(getWavesurfer.value.regions.list)[0]].end.toFixed(2);
      const originalBuffer = getWavesurfer.value.backend.buffer;
      let emptySegment = getWavesurfer.value.backend.ac.createBuffer(
        originalBuffer.numberOfChannels,
        //segment duration
        (end - start) * (originalBuffer.sampleRate * 1),
        originalBuffer.sampleRate
      );

      for (let i = 0; i < originalBuffer.numberOfChannels; i++) {
        let chanData = originalBuffer.getChannelData(i);
        let segmentChanData = emptySegment.getChannelData(i);
        for (let j = 0, len = chanData.length; j < end * originalBuffer.sampleRate; j++) {
          segmentChanData[j] = chanData[j + (start * originalBuffer.sampleRate)];
        }
      }

      getWavesurfer.value.loadDecodedBuffer(emptySegment);
    }

    const bufferToWave = (abuffer, offset, len) => {
      var numOfChan = abuffer.numberOfChannels,
          length = len * numOfChan * 2 + 44,
          buffer = new ArrayBuffer(length),
          view = new DataView(buffer),
          channels = [], i, sample,
          pos = 0;

      // write WAVE header
      setUint32(0x46464952);                         // "RIFF"
      setUint32(length - 8);                         // file length - 8
      setUint32(0x45564157);                         // "WAVE"

      setUint32(0x20746d66);                         // "fmt " chunk
      setUint32(16);                                 // length = 16
      setUint16(1);                                  // PCM (uncompressed)
      setUint16(numOfChan);
      setUint32(abuffer.sampleRate);
      setUint32(abuffer.sampleRate * 2 * numOfChan); // avg. bytes/sec
      setUint16(numOfChan * 2);                      // block-align
      setUint16(16);                                 // 16-bit (hardcoded in this demo)

      setUint32(0x61746164);                         // "data" - chunk
      setUint32(length - pos - 4);                   // chunk length

      // write interleaved data
      for(i = 0; i < abuffer.numberOfChannels; i++)
        channels.push(abuffer.getChannelData(i));

      while(pos < length) {
        for(i = 0; i < numOfChan; i++) {             // interleave channels
          sample = Math.max(-1, Math.min(1, channels[i][offset])); // clamp
          sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767)|0; // scale to 16-bit signed int
          view.setInt16(pos, sample, true);          // update data chunk
          pos += 2;
        }
        offset++                                     // next source sample
      }

      // create Blob
      return new Blob([buffer], {type: "audio/wav"});

      function setUint16(data) {
        view.setUint16(pos, data, true);
        pos += 2;
      }

      function setUint32(data) {
        view.setUint32(pos, data, true);
        pos += 4;
      }
    }

    const startEdit = () => {
      getWavesurfer.value.regions.clear();
      getWavesurfer.value.regions.add({
        start: 0,
        end: getWavesurfer.value.getDuration() - (getWavesurfer.value.getDuration() / 60),
        color: 'hsla(200, 50%, 70%, 0.3)',
      })
      editBtnVisible.value = true
    }

    const getResult = computed(() => (
      getWavesurfer.value.exportPCM(1024, 10000, true, 0)
    ));

    const stopEdit = async () => {
      getWavesurfer.value.regions.clear();
      editBtnVisible.value = false;
      let link = Upload(bufferToWave(getWavesurfer.value.backend.buffer, 0, getWavesurfer.value.backend.buffer.length))
      sendActionEditAudioEvent(link)
    }

    const Upload = async (mp3) => {
      let name
      const data = new FormData();
      data.append('file', mp3);
      await axios.post(UploadAudioURL.value, data, {
        headers: {
          'Content-Type': `multipart/form-data;`,
        },
        timeout: 30000,
      }).then(function (response) {
          name = response.data.data.name;
        })
        .catch(function (response) {
          notification['error'] ({
                message: "Аудио не загрузилось. Попробуйте еще раз.",
          });
        });
        return name
    }

    const sendActionEditAudioEvent = (link) => {
      context.emit('actionEditAudio', link)
    };

    const sendActionDestroyAudioEvent = () => {
      context.emit('actionDestroyAudio')
    };

    const UploadAudioURL = computed(() => store.getters['url/UploadAudioURL']);
    const DeleteAudioURL = computed(() => store.getters['url/DeleteAudioURL']);

    const onDelete = async() => {
      await axios.delete( 
          DeleteAudioURL.value + '/' + props.letterNumber
      ).then(() => {
          notification['success'] ({
                message: "Аудио успешно удалено.",
          });
          getWavesurfer.value.destroy()
          sendActionDestroyAudioEvent()
      }).catch(() => {
          notification['error'] ({
                message: "Ошибка удаления аудио. Попробуйте еще раз.",
          });
      })
      
    }

    onMounted(() => {
      reload()
    });

    onUnmounted(() => {
      getWavesurfer.value.destroy()
    });

    return {
      onDelete,
      reload,
      onPlay,
      onDownload,
      startEdit,
      trim,
      editBtnVisible,
      deleteChunk,
      stopEdit,
      playerLoading
    }

  }

})
</script>

<style>
#wave {
  background: #333;
  
}
.back {
  background: #46a6d8;
}
.wave {
  background: #333;
}
#forRecord {
 display: none;
}
.record-container {
  display: none;
  padding: 10px;
  z-index:9999;
  width:100%;
  height:140px;
  background:black;
  position:absolute;
  top:0;
  color: white;
  text-align: center;
  padding-top: 50px;
}
.waveform-container {
    background: #ccfcfc !important;
    padding: 5px !important;
    border: 3px !important;
    border-color: red !important;
    border-radius: 7px !important;
}

showtitle, cursor{
    z-index: 5 !important;
}

.wavesurfer-handle {
  position: fixed;
  height: 130px !important;
  z-index: 15 !important;
  width: 7px !important;
  max-width: 7px !important;
  background: #CCAADD;
  border-radius: 5px;
}
</style>