<template>
  <a-layout style="min-height: 100vh">
    <a-layout-sider v-model:collapsed="collapsed" collapsible>
      <div class="logo" />
      <a-menu theme="dark" v-model:selectedKeys="selectedKeys" mode="inline">
        <router-link :to="{
          name:'Cities'  
        }">
            <a-menu-item key="1">
            <desktop-outlined />
            <span>Города</span>
            </a-menu-item>
        </router-link>
        <router-link :to="{
          name:'Letters'  
        }">
            <a-menu-item key="2">
            <desktop-outlined />
            <span>Письма</span>
            </a-menu-item>
        </router-link>
        <a-sub-menu key="sub1">
          <template #title>
            <span>
              <user-outlined />
              <span>Users</span>
            </span>
          </template>
          <a-menu-item key="3">Tom</a-menu-item>
        </a-sub-menu>
        <a-sub-menu key="sub2">
          <template #title>
            <span>
              <team-outlined />
              <span>Team</span>
            </span>
          </template>
          <a-menu-item key="6">Team 1</a-menu-item>
          <a-menu-item key="8">Team 2</a-menu-item>
        </a-sub-menu>
        <a-menu-item key="9">
          <file-outlined />
          <span>File</span>
        </a-menu-item>
      </a-menu>
    </a-layout-sider>
    <a-layout>
      <a-layout-header type="flex" style="background: #2c3e50; color:white; padding: 0">
        <a-row>
        <a-col :span="6">{{ role }}</a-col>
        <a-col :span="12"></a-col>
        <a-col :span="6"><a-button type="link" @click.prevent="logout">Logout</a-button></a-col>
        </a-row>
      </a-layout-header>  
      <a-layout-content style="margin: 0 16px">
        <a-breadcrumb style="margin: 16px 0">
          <a-breadcrumb-item>User</a-breadcrumb-item>
          <a-breadcrumb-item>Bill</a-breadcrumb-item>
        </a-breadcrumb>
        <div :style="{ padding: '24px', background: '#fff', minHeight: '360px' }">
          <Cities />
        </div>
      </a-layout-content>
      <a-layout-footer style="text-align: center">
        Ant Design ©2018 Created by Ant UED
      </a-layout-footer>
    </a-layout>
  </a-layout>
</template>
<script>
import {
  PieChartOutlined,
  DesktopOutlined,
  UserOutlined,
  TeamOutlined,
  FileOutlined,
} from '@ant-design/icons-vue'
import { defineComponent, ref } from 'vue'
import { mapGetters, mapActions } from 'vuex'
import Cities from '@/components/Cities.vue'
export default defineComponent({
  components: {
    PieChartOutlined,
    DesktopOutlined,
    UserOutlined,
    TeamOutlined,
    FileOutlined,
    Cities
  },

  computed: {
    ...mapGetters({
        auth: 'auth/auth',
        user: 'auth/user',
        role: 'auth/role'
    }),
  },

  methods:{
    ...mapActions({
      logoutAction: 'auth/logout'
    }),

    logout(){
      this.logoutAction().then(()=>{
        this.$router.replace({
          name:'Login'
        })
      })
    }
  },

  data() {
    return {
      collapsed: ref(false),
      selectedKeys: ref(['1']),
      cities: null,
    };
  },
});
</script>
<style>
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>