<template>
    <div v-show="!loading">
      <div id="wave"></div>
      <div id="timeline"></div>
      <div class="controls" style="padding:30px">
              <a-button type="primary" @click="onPlay">
              <CaretRightOutlined />
              /
              <PauseOutlined />
              </a-button>
              <a-button style="margin:20px" type="primary" @click="onDownload">
              <CloudDownloadOutlined />
              </a-button>
      </div>
    </div>
    <a-spin size="large" v-if="loading"/>
</template>

<script>

import { defineComponent, onMounted, computed, onUnmounted, watch, ref } from 'vue'
import WaveSurfer from "wavesurfer.js"
import RegionPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.min.js';
import { 
  CaretRightOutlined,
  PauseOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons-vue';

export default defineComponent({
    
    components: {
        CaretRightOutlined,
        PauseOutlined,
        CloudDownloadOutlined
    },

    props: ['link'],

    setup(props) {
        const loading = ref(true);
        watch(() => props.link, (link) => {
          if(link != undefined) {
            wave(link);
          }
        });

        const wave = async(link) => {
            await getWavesurfer.value.load(link);
            loading.value = false;
        }

        const getWavesurfer = computed(() =>  WaveSurfer.create({
            container: '#wave',
            waveColor: '#46a6d8',
            barWidth: 3,
            barGap: 2,
            height: 130,
            cursorWidth: 1,
            cursorColor: "white",
            //pixelRatio: 1,
            //scrollParent: true,
            responsive: 1000,
            normalize: true,
            plugins: [
                RegionPlugin.create({
                        regions: [
                        
                        ],
                        dragSelection: false,
                        slop: 10,
                    }),
                TimelinePlugin.create({
                container:"#timeline"
                }),
            ],
        //  maxCanvasWidth: 100
        }));
        
        const onPlay = () => {
          getWavesurfer.value.playPause();
        }

        const onDownload = () => {
            const url = props.link;
            window.location.href = url;
        }

        onUnmounted( () => {
          getWavesurfer.value.destroy()
        } 
        )

        return {
          onDownload,
          onPlay,
          loading
        }

    }

})
</script>

<style>
#wave {
  background: #333;
  
}
.back {
  background: #46a6d8;
}
.wave {
  background: #333;
}
#forRecord {
 display: none;
}
.buttons {
  padding: 10px;
}
.record-container {
  display: none;
  padding: 10px;
  z-index:9999;
  width:100%;
  height:140px;
  background:black;
  position:absolute;
  top:0;
  color: white;
  text-align: center;
  padding-top: 50px;
}
.waveform-container {
    background: #ccfcfc !important;
    padding: 5px !important;
    border: 3px !important;
    border-color: red !important;
    border-radius: 7px !important;
}

showtitle, cursor{
    z-index: 5 !important;
}

.wavesurfer-handle {
  position: fixed;
  height: 130px !important;
  z-index: 15 !important;
  width: 7px !important;
  max-width: 7px !important;
  background: #CCAADD;
  border-radius: 5px;
}
</style>