<template>
  <a-row style="padding: 30px">
    <a-col :span="18" justify="start">
        Выбор города: 
        <a-select style="width: 120px" @change="handleCityChange">
            <a-select-option v-for="city in cities" :key="city.id" :value="city.id"> 
                {{ city.name }}
            </a-select-option>
        </a-select>
    </a-col>
    <a-col :span="4" v-if="role == 'admin'">
        <router-link :to="{
                name:'LettersCreate'  
            }">
        <a-button>
            <template #icon><PlusOutlined /></template>
            Создать письмо
        </a-button>
        </router-link>
    </a-col>
  </a-row>
  <a-table 
    :columns="columns" 
    :data-source="letters"
    :loading="loading"
    :pagination="{ pageSize: 100 }"
    @change="handleTableChange"
    >
    <template #action="{ record }">
      <router-link :to="{ name: 'LetterView', params: { letterId: record.id }}">
          <ViewBtn />
      </router-link>
      <span v-if="role == 'admin'">
        <router-link :to="{ name: 'LetterEdit', params: { letterId: record.id }}" style="margin: 0 5px;">
          <a-button type="primary">
            <EditOutlined />
          </a-button>
        </router-link>
        <a-popconfirm title="Удалить письмо ?" ok-text="Да" cancel-text="Нет" @confirm="deleteLetter(record.id, city)">
          <a-button type="danger">
            <DeleteOutlined />
          </a-button>
        </a-popconfirm>
      </span>
    </template>
    <template #is_verified="{ record }">
      <CheckCircleTwoTone twoToneColor="#52c41a" style="fontSize: 25px" v-if = "record.is_verified"/>
      <CloseCircleTwoTone twoToneColor="#FF5240" style="fontSize: 25px" v-if = "!record.is_verified"/>
    </template>
    <template #poi="{ record }">
      <CheckCircleTwoTone twoToneColor="#52c41a" style="fontSize: 25px" v-if = "record.poi"/>
      <CloseCircleTwoTone twoToneColor="#FF5240" style="fontSize: 25px" v-if = "!record.poi"/>
    </template>
  </a-table>
</template>
<script>

import { defineComponent, onMounted, ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'
import axios from 'axios'
import { useStore } from 'vuex'
import ViewBtn from '@/components/ViewBtn.vue'
import { 
  PlusOutlined, 
  EditOutlined, 
  DeleteOutlined, 
  CheckCircleTwoTone,
  CloseCircleTwoTone, 
} from '@ant-design/icons-vue';

const columns = [
  {
    title: 'id',  
    dataIndex: 'id',
    key: 'id',
    sorter: true,
  },
  {
    title: 'Автор',  
    dataIndex: 'author',
    key: 'author',
    sorter: true,
  },
  {
    title: 'Кому',
    dataIndex: 'to',
    key: 'to',
    sorter: true,
  },
  {
    title: 'Год',
    dataIndex: 'year',
    key: 'year',
    sorter: true,
  },
  {
    title: 'Опубликовано',
    key: 'is_verified',
    dataIndex: 'is_verified',
    slots: {
      customRender: 'is_verified',
    },
    filters: [
      {
        text: 'Да',
        value: 'true',
      },
      {
        text: 'Нет',
        value: 'false',
      },
    ],
    filterMultiple: false,
    width: 150,
  },
  
  {
    title: 'Точка интереса',
    key: 'poi',
    dataIndex: 'poi',
    slots: {
      customRender: 'poi',
    },
    filters: [
      {
        text: 'Да',
        value: 'true',
      },
      {
        text: 'Нет',
        value: 'false',
      },
    ],
    filterMultiple: false,
    width: 150,
  },
  {
    title: 'Действия',
    key: 'action',
    slots: {
      customRender: 'action',
    },
  },
];

export default defineComponent({
  
  components: {
    PlusOutlined,
    EditOutlined,
    DeleteOutlined,
    ViewBtn,
    CheckCircleTwoTone,
    CloseCircleTwoTone
  },

  setup() {
    const route = useRoute()
    const store = useStore();
    const cities = ref();
    const letters = ref();
    const currentCity = ref();
    const isVerified = ref();
    const poi = ref();
    const sortField = ref();
    const sortOrder = ref();
    const loading = ref(false);
    const role = computed(() => store.getters['auth/role']);

    watch(
      () => route.params,
      async () => {
        await getLetters()
      }
    )

    const handleTableChange = (pag, filters, sorter) => {
        sortField.value = sorter.field;
        sortOrder.value = sorter.order;
        isVerified.value = filters.is_verified;
        poi.value = filters.poi;
        changeQuery()
    };

    const getLetters = async () => {
        loading.value = true;
        let res = await axios.get('admin/letters/all'+window.location.search);
        letters.value = res.data.data.payload;
        loading.value = false;
    }; 

    const getCities = async() => {
        let response = await axios.get('api/cities/all');
        cities.value = response.data.data.payload;
    };

    const handleCityChange = city => {
        currentCity.value = city;
        changeQuery()
    };

    const deleteLetter = async (id) => {
        await axios.delete('api/letters/delete/'+id);
        getLetters();
    };

    const changeQuery = () => {
        router.replace({query: { isVerified: isVerified.value, poi: poi.value, sortField: sortField.value, sortOrder: sortOrder.value, city: currentCity.value}})
    }

    onMounted (
      getLetters(),
      getCities()
    )

    return {
      handleTableChange,
      handleCityChange,
      deleteLetter,
      cities,
      letters,
      columns,
      role,
      loading
    };
  },

});
</script>
