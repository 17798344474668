<template>
  
          <Users />
      
</template>
<script>
import { defineComponent } from 'vue'
import NavBar from '@/components/partials/NavBar.vue'
import Header from '@/components/partials/Header.vue'
import Footer from '@/components/partials/Footer.vue'
import Users from '@/components/Users.vue'
import axios from 'axios'

export default defineComponent({
  components: {
    NavBar,
    Header,
    Footer,
    Users
  },
  mounted() {
      this.getUsers()
  },
  methods:{
      getUsers() {
        try {   
            axios.get('admin/users')
                 .then(response => ( 
                        this.users = response.data
                    ));
        } catch(e) {
            console.log(e)
        }
      },   
  },
  data() {
    return {
        users: null,
    };
  },
});
</script>