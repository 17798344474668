<template>
  
        <a-row style="padding: 30px">
          <a-col :span="12">
              Выбор города: 
              <a-select style="width: 120px" @change="cityChange">
                  <a-select-option v-for="city in data.cities" :key="city.id" :value="city.id"> 
                      {{ city.name }}
                  </a-select-option>
              </a-select>
          </a-col>
        </a-row>
          <a-table :columns="columns" :data-source="data.letters">
            <template #action="{ record }">
                <router-link :to="{ name: 'LetterView', params: { letterId: record.id }}">
                  <ViewBtn />
                </router-link>
                <a-divider type="horizontal" />
                <router-link :to="{ name: 'LetterEdit', params: { letterId: record.id }}">
                <a-button type="dashed">
                  <EditOutlined />
                </a-button>
                </router-link>
                <a-divider type="horizontal" />
                <a-button type="primary" @click="verified(record.id, cityId)">
                  <CheckOutlined />
                </a-button>
                <a-divider type="horizontal" />
                <a-button type="danger" @click="showModal(record.id)">
                  <a-modal v-model:visible="visible" title="Вернуть отправителю" @ok="unverified(message)">
                    <p>Письмо номер №{{letterId}}</p>
                    <a-textarea v-model:value="message" placeholder="Ваши замечания по письму" :rows="4" />
                  </a-modal>
                  <CloseOutlined />
                </a-button>
            </template>
          </a-table>
      
</template>
<script>
import { defineComponent, reactive, onMounted, ref } from 'vue'
import axios from 'axios' 
import NavBar from '@/components/partials/NavBar.vue'
import Header from '@/components/partials/Header.vue'
import Footer from '@/components/partials/Footer.vue'
import ViewBtn from '@/components/ViewBtn.vue'
import {
  CheckOutlined,
  CloseOutlined,
  EditOutlined
} from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    NavBar,
    Header,
    Footer,
    CheckOutlined,
    EditOutlined,
    CloseOutlined,
    ViewBtn
  },

  setup() {
    const store = useStore()
    const data = reactive({
      letters: null,
      cities: null
    })

   const columns = [
      {
        title: 'id',  
        dataIndex: 'id',
        key: 'id',
      },
      {
        title: 'Автор',  
        dataIndex: 'author',
        key: 'author',
      },
      {
        title: 'Кому',
        dataIndex: 'to',
        key: 'to',
      },
      {
        title: 'Год',
        dataIndex: 'year',
        key: 'year',
      },
      {
        title: 'Действия',
        key: 'action',
        slots: {
          customRender: 'action',
        },
        width: 100,
      },
    ];
    
    const visible = ref(false);
    const letterId = ref(0);
    const cityId = ref('');

    const showModal = (id) => {
      letterId.value = id;
      visible.value = true;
    };

    const cityChange = (value) => {
      cityId.value = value
      getLetters(value)
    };

    const verified = (value, city = "") => {
      axios
        .get('/admin/letters/publish/'+value)
        .then(response => {
          console.log(response)
          notification['success']({
            message: response.data,
          });
          getLetters(city)
        })
        .catch(error => {
           notification['success']({
            message: error.message,
          });
        })
    };

    const unverified = async (message) => {
      axios
        .post('/admin/letters/unverified/create', {
          letter_id: letterId.value,
          message: message
        })
        .then(response => {
          getLetters(cityId.value)
          visible.value = false;
          notification['success']({
            message: response.data,
          });
        })
        .catch(error => {
          console.log(error)
          notification['error']({
            message: error.message,
          });
        })
    };

    const getLetters = (city = "") => {
      axios
        .get('/admin/letters/all/unpublished/'+city)
        .then(response => {
          data.letters = response.data.data.payload
        })
        .catch(error => {
          console.log(error);
        })
      store.dispatch('unpublishedCount/fetchCount')
    }

    const getCities = () => {
      axios
        .get('api/cities/all')
        .then(response => (
          data.cities = response.data.data.payload
        ))
        .catch(error => (
          console.log(error)
        ))
    }

    onMounted(() => {
      getLetters(),
      getCities()
    })

    return {
      data,
      columns,
      verified,
      unverified,
      cityChange,
      showModal,
      visible,
      cityId,
      letterId
    };
  },
  
});
</script>