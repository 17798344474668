<template>
    <a-row style="padding: 30px">
      <a-col :span="18"></a-col>
      <a-col :span="6" v-if="role == 'admin'">
          <router-link :to="{ name: 'LetterEdit', params: { letterId: data.letter.id }}">
          <a-button type="primary">
              <template #icon><EditOutlined /></template>
              Редактировать письмо
          </a-button>
          </router-link>
      </a-col>
    </a-row>
    <a-row 
      type="flex"
      justify="space-between"
      >
      <a-col :flex="16">
        <a-card>
            <a-col :span="24"><h2>Письмо №{{data.letter.id}} </h2></a-col>
            <a-col :span="24" align="start">
              <h3>Изображение метки: </h3>
              <a-image :width="200" :src="data.letter.label_image"/>
            </a-col>
            <a-col :span="24" align="start"><h3 style="padding-top:20px">Автор: {{data.letter.author}}</h3></a-col> 
            <a-col :span="24" align="start"><h3>Кому: {{data.letter.to}}</h3></a-col> 
            <a-col :span="24" align="start"><h3>Год: {{data.letter.year}}</h3></a-col>
            <a-col :span="24" align="start"></a-col>
            <a-col :span="24" align="start"> <h3>Изображения:</h3>
                <a-image-preview-group>
                  <a-image :width="150" v-for="image in data.letter.images" :key="image.order" :src="image.src" />
                </a-image-preview-group>
            </a-col>
            <a-image-preview-group>
              <a-image :width="200" v-for="image in data.letter.images" :key="image.order" :src="image.link" />
            </a-image-preview-group>
            <a-col :span="24" align="start"><h3>Описание: {{data.letter.description}}</h3></a-col>
            <a-col :span="24" align="start"><AddressView :location="data.letter.location"/></a-col>
            <a-col :span="24"><h3>Прослушать письмо: </h3>
                              <AudioPlayer :link="data.letter.audio"/>
            </a-col>
        </a-card>
      </a-col>
      <a-col :flex="6">
          <a-card>
            <a-col :span="24" align="start">
              <h3>Точка интереса: 
                <CheckCircleTwoTone twoToneColor="#52c41a" style="fontSize: 25px" v-if = "data.letter.poi"/>
                <CloseCircleTwoTone twoToneColor="#FF5240" style="fontSize: 25px" v-if = "!data.letter.poi"/>
              </h3>
            </a-col>
            <a-col :span="24" align="start">
              <h3>Опубликовано: 
                <CheckCircleTwoTone twoToneColor="#52c41a" style="fontSize: 25px" v-if = "data.letter.is_verified"/>
                <CloseCircleTwoTone twoToneColor="#FF5240" style="fontSize: 25px" v-if = "!data.letter.is_verified"/>
              </h3>
            </a-col>
          </a-card>
      </a-col> 
    </a-row>
</template>

<script>
import AudioPlayer from '@/components/audio/AudioPlayer.vue'
import AddressView from '@/components/AddressView.vue'
import { defineComponent, reactive, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import axios from 'axios'
import { mapGetters } from 'vuex'
import { 
  EditOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
} from '@ant-design/icons-vue';

export default defineComponent({
  components: {
    EditOutlined,
    CheckCircleTwoTone,
    CloseCircleTwoTone,
    AudioPlayer,
    AddressView,
  },

  computed: {
        ...mapGetters({
            role: 'auth/role',
        }),

  },

  setup () {
    const route = useRoute();
    const data = reactive({
      letter:{
        id: null,
        author: '',
        from: '',
        to:'',
        year: null,
        audio: null,
        location: {},
        images: {},
        label_image: '',
        description: '',
        city_id: null,
        is_verified: false,
        poi: false,
      },
    })

    const getLetter = () => {
      axios
        .get('api/letters/item/'+route.params.letterId)
        .then(response => { 
          console.log(response)
          data.letter = response.data.data
        })
        .catch(error => {
          console.log(error);
        })
    }

    onMounted( () => {
      getLetter()
    })

    return {
      data,
    }
  },

});
</script>
