
export default {
    namespaced:true,
    
    state:{
        UploadHost: process.env.VUE_APP_API_URL+"api/letters/upload",
        UploadLabelImageURL: "/label-image",
        UploadImagesURL: "/images",
        UploadAudioURL: "/audio",
        DeleteAudioURL: "/audio",
    },

    getters: {
        UploadLabelImageURL(state) {
            return state.UploadHost+ state.UploadLabelImageURL
        },
        UploadImagesURL(state) {
            return state.UploadHost+ state.UploadImagesURL
        },
        UploadAudioURL(state) {
            return state.UploadHost+ state.UploadAudioURL
        },
        DeleteAudioURL(state) {
            return state.UploadHost+ state.DeleteAudioURL
        },
        
    },

}
