<template>
    <a-button type="default" style="
        background-color: rgb(171, 171, 171);
        color: rgb(255, 255, 255);
        border-color: #909090;
    ">
        <EyeOutlined />
    </a-button>
</template>

<script>
import { 
  EyeOutlined
} from '@ant-design/icons-vue';
export default {
    components:{
        EyeOutlined
    }
}
</script>