<template>
  
  <a-row style="padding: 30px">
    <a-col :span="24">
        <router-link :to="{name:'UserCreate'}">
        <a-button>
            <template #icon><PlusOutlined /></template>
            Создать модератора
        </a-button>
        </router-link>
    </a-col>
  </a-row>
  <a-table :columns="columns" :data-source="users">
    <template #action="{ record }">
      <span>
          <router-link :to="{ name: 'UserEdit', params: { userId: record.id }}">
              <a-button type="primary">
                Редактировать
              </a-button>
          </router-link>
        <a-divider type="vertical" />
          <a-popconfirm :title="'Удалить пользователя '+record.email+' ?'" ok-text="Да" cancel-text="Нет" @confirm="deleteUser(record.id)">
            <a-button type="danger">
              Удалить
            </a-button>
          </a-popconfirm>
        <a-divider type="vertical" />
        <a-popconfirm :title="'Сменить пароль для '+record.email+' ?'" ok-text="Да" cancel-text="Нет" @confirm="inviteUser(record.id)">
          <a-button type="dashed">
            Сменить пароль 一 {{ record.email }}
          </a-button>
        </a-popconfirm>
      </span>
    </template>
  </a-table>
</template>
<script>
import { SmileOutlined, PlusOutlined, DownOutlined } from '@ant-design/icons-vue';
import { defineComponent } from 'vue';
import axios from 'axios'
import { notification } from 'ant-design-vue';

const columns = [
  {
    title: 'id',
    dataIndex: 'id',
    key: 'id'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email'
  },
  {
    title: 'Роль',
    dataIndex: 'my_roles[0].name',
    key: 'role',
  },
  {
    title: 'Действия',
    key: 'action',
    slots: {
      customRender: 'action',
    },
  },
];
export default defineComponent({
  setup() {
    return {
      columns,
    };
  },

  components: {
    SmileOutlined,
    DownOutlined,
    PlusOutlined
  },

  mounted() {
      this.getUsers()
  },
  methods:{
      getUsers() {
        try {   
            axios.get('admin/users')
                 .then(response => ( 
                        this.users = response.data
                    ));
        } catch(e) {
            console.log(e)
        }
      },
      deleteUser(id) {
          axios.get('admin/users/delete/'+id)
              .then(response => ( 
                  this.getUsers()
              ));
      },
      inviteUser(id) {
          axios.get('admin/users/invite/'+id)
               .then( () => {
                  notification['success'] ({
                    message: "Новый пароль успешно отправлен.",
                  });
               });
      }
  },
  data() {
    return {
        users: null,
    };
  },
});
</script>