<template>
    <h3>Адрес: {{ address.value }}</h3>
</template>
<script>
import { defineComponent, watch, ref } from 'vue';

export default defineComponent({
    
    props: ['location'],

    setup(props) {
        const address = ref('');
        watch(() => props.location, (location) => {
            geolocate(location);
        });

        const token = process.env.VUE_APP_DADATA_TOKEN;
            
        const geolocate = (location) => {
            var url = "https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address";
            var query = location;

            var options = {
                method: "POST",
                mode: "cors",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": "Token " + token
                },
                body: JSON.stringify(query)
            }

            fetch(url, options)
            .then(response => response.text())
            .then(result => (
                address.value = JSON.parse(result).suggestions[0]
            ))
            .catch(error => console.log("error", error))
        }

        return {
            address
        }

    },
})
</script>
