import axios from "axios"
import router from '@/router'
import { notification } from 'ant-design-vue';

export default {
    namespaced:true,
    state:{
        token: null,
        user: null,
        role: null,
    },

    getters: {
        auth(state) {
            return state.token && state.user
        },

        user(state) {
            return state.user
        },

        role(state) {
            return state.role
        },
        
        token(state) {
            return state.token
        },
        
    },

    mutations:{
        SET_TOKEN (state, token){
            state.token = token
        },
        SET_USER (state, data){
            state.user = data
        },
        SET_ROLE (state, data){
            state.role = data
        },
    },

    actions:{

        // Login
        login({dispatch}, credentials) {
            axios.post('auth/admin-login', credentials)
                .then(response => {
                    dispatch('attempt', response.data.access_token)
                }).catch(error => {
                    notification['error']({
                        message: 'Ошибка авторизации',
                        description: error,
                    });
                })
        },

        async attempt({ commit, state }, token){
            if (token) {
                commit('SET_TOKEN', token)
            }

            if (!state.token) {
                return
            }

            const headers = {
                'Accept': 'application/json',
                'Authorization': 'Bearer '+token
            }

            try {
                let response = await axios.get('auth/me')
                commit('SET_USER', response.data)
                let role = await axios.get('admin/current-role')
                commit('SET_ROLE', role.data)
                router.push("Letters")
            } catch (e) {
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
                commit('SET_ROLE', null)
            }
        },

        logout({ commit }){
            return axios.get('auth/logout').then(()=>{
                commit('SET_TOKEN', null)
                commit('SET_USER', null)
                commit('SET_ROLE', null)
            })
        }
    }

}