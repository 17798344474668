<template>
  <a-layout style="min-height: 100vh" v-if="role">
    <NavBar />
    <a-layout>
      <Header />  
      <a-layout-content style="margin: 0 16px; padding: 20px 100px">
        <router-view/>
      </a-layout-content>
    </a-layout>
  </a-layout> 
  <a-layout style="min-height: 100vh" v-if="!role">
      <a-layout-content style="margin: 0 16px; padding: 20px 100px">
        <router-view/>
      </a-layout-content>
      <Footer />
  </a-layout>
      
</template>

<script>

import NavBar from '@/components/partials/NavBar'
import Header from '@/components/partials/Header'
import Footer from '@/components/partials/Footer'
import { mapGetters } from 'vuex'
export default {

    components: {
        NavBar,
        Header,
        Footer
    },

    computed: {
        ...mapGetters({
            role: 'auth/role',
        }),

    },

    setup() {
        
    },
}
</script>