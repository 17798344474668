<template>
  <Letters />    
</template>
<script>
import { defineComponent } from 'vue'
import Letters from '@/components/Letters.vue'
import NavBar from '@/components/partials/NavBar.vue'
import Header from '@/components/partials/Header.vue'
import Footer from '@/components/partials/Footer.vue'

export default defineComponent({
  components: {
    Letters,
    NavBar,
    Header,
    Footer
  },

});
</script>
<style>
#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
  background: #fff;
}
[data-theme='dark'] .site-layout .site-layout-background {
  background: #141414;
}
</style>