<template>
    <a-layout-sider v-model:collapsed="collapsed" collapsible>
        <div class="logo" />
        <a-menu theme="dark" v-model:selectedKeys="selectedKeys" mode="inline">
        
        <router-link :to="{ name:'Letters' }">
            <a-menu-item key="1">
            <MailOutlined />
            <span>Письма</span>
            </a-menu-item>
        </router-link>
        
        <router-link v-if="role == 'admin'" :to="{ name:'Users' }">
            <a-menu-item key="2">
            <UserOutlined />
            <span>Модераторы</span>
            </a-menu-item>
        </router-link>
        
        <router-link :to="{ name:'LettersUnpublished' }">
            <a-menu-item key="3">
            <a-badge
                :count="count"
                :number-style="{
                backgroundColor: '#fff',
                color: '#999',
                boxShadow: '0 0 0 1px #d9d9d9 inset',
                }"
            />
            <span> Ожидают модерации </span>
            </a-menu-item>
        </router-link>

        </a-menu>
    </a-layout-sider>
        
</template>
<script>
import { defineComponent, ref, onMounted } from 'vue'
import {
  MailOutlined,
  UserOutlined
} from '@ant-design/icons-vue'
import { mapGetters, useStore } from 'vuex'

export default defineComponent({
  components: {
    MailOutlined,
    UserOutlined,
  },

  setup() {
    const store = useStore() 

    onMounted(store.dispatch('unpublishedCount/fetchCount'))
  },

  computed: {
    ...mapGetters({
      role: 'auth/role',
      count: 'unpublishedCount/count',
    }),
  },

  data() {
    return {
      collapsed: ref(false),
      selectedKeys: ref(['1']),
    }
  },
})
</script>
