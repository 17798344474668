<template>
  <a-upload
    :action="UploadAudioURL"
    :headers="headers"
    :multiple="false"
    :file-list="fileList"
    @change="handleChange"
  >
  <div v-if="fileList.length < 1">
    <a-button>
      <upload-outlined></upload-outlined>
      Upload {{token}}
    </a-button>
  </div>
  </a-upload>
</template>
<script>
import { UploadOutlined } from '@ant-design/icons-vue';
import { defineComponent, ref, computed } from 'vue';
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    UploadOutlined,
  },

  emits: ['actionUploadAudio'],

  setup(props, context) {
    const store = useStore();
    const fileList = ref([]);

    const handleChange = info => {
      let resFileList = [...info.fileList]; // 1. Limit the number of uploaded files
      //    Only to show two recent uploaded files, and old ones will be replaced by the new

      resFileList = resFileList.slice(-2); // 2. read from response and show file link

      resFileList = resFileList.map(file => {
        if (file.response) {
          // Component will show file.url as link
          file.url = file.response.url;
        }

        return file;
      });
      fileList.value = resFileList;
      sendActionUploadAudioEvent()
    };

    const sendActionUploadAudioEvent = () => {
       context.emit('actionUploadAudio', fileList)
    };

    const token = computed(() => store.getters['auth/token']);
    const UploadAudioURL = computed(() => store.getters['url/UploadAudioURL']);


    return {
      headers: {
         Authorization: 'bearer ' + token.value,
      },
      UploadAudioURL,
      fileList,
      handleChange,
      sendActionUploadAudioEvent
    };
  },
});
// import { message } from 'ant-design-vue';
// import { UploadOutlined } from '@ant-design/icons-vue';
// import { defineComponent, ref } from 'vue';

// export default defineComponent({
//   components: {
//     UploadOutlined,
//   },

//   emits: ['actionUploadAudio'],

//   setup(props, context) {
//     const handleChange = async info => {
//       if (info.file.status !== 'uploading') {
//         console.log(info.file, info.fileList);
//       }

//       if (info.file.status === 'done') {
//         message.success(`${info.file.name} file uploaded successfully`);
//         sendActionUploadAudioEvent()
//       } else if (info.file.status === 'error') {
//         message.error(`${info.file.name} file upload failed.`);
//       }
//     };

//     const sendActionUploadAudioEvent = () => {
//       context.emit('actionUploadAudio', fileList)
//     }

//     const fileList = ref([]);
//     return {
//       fileList,
//       headers: {
//         authorization: 'authorization-text',
//       },
//       handleChange,
//       sendActionUploadAudioEvent
//     };
//   },
// });
// emits: ['actionUploadAudio'],
// const sendActionUploadAudioEvent = () => {
//       context.emit('actionUploadAudio', fileList)
//     }
</script>