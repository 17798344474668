import axios from 'axios'

export default {
    namespaced:true,
    state:{
        count: 0,
    },

    getters: {
        count(state) {
            return state.count
        },
        
    },

    actions: {
        fetchCount({commit}) {
            axios
                .get('/admin/letters/all/unpublished-count')
                .then(response => {
                    commit('setCount',response.data);
                })
        }
    },

    mutations:{
        setCount(state, newCount) {
            state.count = newCount
        },
    }

}
