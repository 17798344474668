<template>
  <a-form
    ref="formRef"
    :model="formState.data"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
    >
    <a-row 
      type="flex"
      justify="space-between"
      >
      <a-col :flex="16">
        <a-card>
          <h2>Письмо №{{formState.data.id}} </h2>
          <a-form-item ref="author" label="Автор: " name="author">
            <a-input v-model:value="formState.data.author" />
          </a-form-item>
          <a-form-item label="Метка: " name="label_image" v-if="!labelImageUploadVisible">
            <a-button style="margin-right:20px" type="primary" @click="changeLabelImageUploadVisible(true)" v-if="!labelImageUploadVisible">Изменить</a-button>
            
            <a-popconfirm :title="'Удалить изображение ?'" ok-text="Да" cancel-text="Нет" @confirm="deleteLabelImage()">
              <a-button style="margin-right:20px" type="danger" v-if="formState.data.label_image">Удалить изображение</a-button>
            </a-popconfirm>
            <a-image
              :width="200"
              :src="formState.data.label_image"
              />
          </a-form-item>
          <a-form-item  label="Метка: " name="label_image" v-if="labelImageUploadVisible">
            <UploadLabelImage @actionUploadLabelImage="addLabelImageToForm"/>
          </a-form-item>
          <a-form-item name="to" ref="to" label="Кому: ">
            <a-input v-model:value="formState.data.to" />
          </a-form-item>
          <a-form-item name="year" ref="year" label="Год: ">
            <a-input v-model:value="formState.data.year" />
          </a-form-item>
          <a-form-item name="description" ref="description" label="Описание: ">
            <a-textarea v-model:value="formState.data.description" />
          </a-form-item>
          <a-form-item  label="Изображения: " name="images">
            <UploadImages @actionUploadImages="addImagesToForm" :images="formState.data.images"/>
          </a-form-item>
           <a-form-item  label="Адрес: " name="location">
            <VueSuggestions
              v-model:model.sync="dadata.city"
              v-model:kladr_id.sync="dadata.kladrID"
              v-model:location.sync="formState.data.location"
              :placeholder="'Начните вводить'"
              :class="form-control"
              :options="dadata.suggestionOptions"
              :oldLocation="oldLocation"
              >
            </VueSuggestions>            
          </a-form-item>     

          <a-form-item  label="Аудио: " name="audio" v-if="formState.data.is_processed === 'null'">
            <AudioUpload @actionUploadAudio="addCreatedAudioToForm"/>
          </a-form-item>

          <div><h1 v-if="formState.data.is_processed == 'false'">Аудио в обработке</h1></div>

          <a-form-item name="audio" label="Аудио: " v-if="formState.data.is_processed == 'true'">
            <AudioEditor @actionEditAudio="addAudioToForm" @actionDestroyAudio="audioDestroy" :link="formState.data.audio" :letterNumber="formState.data.id"/>
          </a-form-item>
        </a-card>
      </a-col>
      <a-col :flex="4">
        <a-card>
          <a-form-item label="Точка интереса: " name="poi">
            <a-switch v-model:checked="formState.data.poi" />
          </a-form-item>
          <a-form-item label="Опубликовать: " name="is_verified">
            <a-switch v-model:checked="formState.data.is_verified" />
          </a-form-item>
          <a-form-item>
            <a-button type="primary" @click="onSubmit">Обновить</a-button>
          </a-form-item>
        </a-card>
      </a-col>
    </a-row>
  </a-form>
</template>

<script>
import UploadImages from '@/components/images/UploadImages.vue';
import UploadLabelImage from '@/components/images/UploadLabelImage.vue';
import { defineComponent, ref, reactive, toRaw, onMounted, computed } from 'vue';
import { useRoute } from 'vue-router';
import AudioEditor from '@/components/audio/AudioEditor.vue';
import Letters from '@/components/Letters.vue';
import axios from 'axios';
import { notification } from 'ant-design-vue';
import VueSuggestions from 'vue-suggestions';
import AudioUpload from '@/components/audio/AudioUpload.vue';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    Letters,
    AudioEditor, 
    UploadLabelImage, 
    UploadImages,
    VueSuggestions,
    AudioUpload
  },

  setup() {

    const store = useStore();
    const route = useRoute()
    const formRef = ref();
    const oldLocation = ref();
    const labelImageUploadVisible = ref(false);
    const dadata = reactive({
      city: '',
      kladrID: 0,
      suggestionOptions: {
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454318
        token: process.env.VUE_APP_DADATA_TOKEN,
        type: "ADDRESS",
        scrollOnFocus: false,
        triggerSelectOnBlur: false,
        triggerSelectOnEnter: true,
        addon: 'clear',
        // @see https://confluence.hflabs.ru/pages/viewpage.action?pageId=207454320
        onSelect (suggestion) {
        }
      }
    });
    const formState = reactive({
      data: {
        author: '',
        label_image: '',
        from: '',
        to: '',
        year: '',
        is_verified: '',
        is_processed: '',
        description: '',
        images:'',
        audio: '',
        new_audio: null,
        location: {
          lat: '',
          lon: ''
        },
        city_id: '',
        user_uuid: '',
        poi: false,
      }
    });
    const rules = {
      author: [
        {
          required: true,
          message: 'поле не должно быть пустым',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 255,
          message: 'Длинна поля должна быть от 3 до 255 символов',
          trigger: 'blur',
        },
      ],
      to: [
        {
          required: true,
          message: 'поле не должно быть пустым',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 255,
          message: 'Длинна поля должна быть от 3 до 255 символов',
          trigger: 'blur',
        },
      ],
      year: [
        {
          required: true,
          message: 'поле не должно быть пустым',
          trigger: 'blur',
        },
        {
          min: 1,
          max: 4,
          message: 'Длинна поля должна быть до 4 символов',
          trigger: 'blur',
        },
      ],
    };
    
    const setFormState = async() => {
      let response = await axios.get('admin/letters/item/'+route.params.letterId);
      formState.data = response.data.data;
      oldLocation.value = response.data.data.location;
      console.log(oldLocation.value);
    };
    
    const onSubmit = () => {
      let result = toRaw(formState.data)
      console.log(result)
      formRef.value
        .validate()
        .then(() => {
          axios.put('admin/letters/update/'+route.params.letterId, {
              author: result.author,
              from: result.from,
              to: result.to,
              year: result.year,
              is_verified: result.is_verified,
              is_processed: result.is_processed,
              poi: result.poi,
              description: result.description,
              images: result.images,
              audio: result.audio,
              new_audio: result.new_audio,
              label_image: result.label_image,
              location: result.location,
              city_id: result.city_id,
              user_uuid: result.user_uuid,
          })
          .then(() => {
            notification['success'] ({
                message: "Письмо успешно обновлено",
            });
          })
          .catch(error => {
            notification['error'] ({
                message: error.message,
            });
          })          
        })
        
    };

    const addLabelImageToForm = data => {
      let res = toRaw(data.value)
      if ( res[0] === undefined) {
        formState.data.label_image = ''
      } else {
        formState.data.label_image = res[0].response.data.name 
      }
    }

    const addImagesToForm = async(data) => {
      if (data != null) {
      let res = toRaw(data)
      let arr = [];
      for (let i = 0; i < res.length; i++) {
        arr.push({
          src:""
        })
      }
      for (let key in res) {
        arr[key].src = await res[key].response.data.name
      }
      formState.data.images = arr
      } 
    } 

    const addAudioToForm = data => {
      Promise.resolve(data).then(function(value) {
        formState.data.new_audio = value
      });
      formState.data.is_processed = "false"
    }

    const addCreatedAudioToForm = data => {
      let res = toRaw(data.value)
      formState.data.audio = res[0].response.data.name
      formState.data.is_processed = "false"
    }

    const changeLabelImageUploadVisible = visable => {
      labelImageUploadVisible.value = visable
    }

    const deleteLabelImage = () => {
       formState.data.label_image = ''
    }

    const audioDestroy = () => {
      formState.data.is_processed = "null"
    }

    const UploadAudioURL = computed(() => store.getters['url/UploadAudioURL']);
    
    onMounted( () => {
      setFormState()
    })

    return {
      formRef,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 20,
      },
      other: '',
      formState,
      rules,
      labelImageUploadVisible,
      addLabelImageToForm,
      changeLabelImageUploadVisible,
      onSubmit,
      addImagesToForm,
      addAudioToForm,
      addCreatedAudioToForm,
      dadata,
      oldLocation,
      audioDestroy,
      deleteLabelImage
    };
  },

})
</script>