import { createStore } from 'vuex'
import auth from './auth'
import unpublishedCount from './unpublishedCount'
import url from './url'


export default createStore({
    state:{
        //
    },

    mutations:{
        //
    },

    actions:{
        //
    },

    modules:{
        auth,
        unpublishedCount,
        url,
    }

})