<template v-if="auth">
  <a-layout class="layout">
    <a-layout-content>
        <a-row type="flex" justify="center">
          <a-col :span="6">
            <div class="login-card">
              <a-form
              layout="horizontal"
              :model="form"
              @submit.prevent="postLogin"
              >
                <h1>Административная панель</h1>
                <a-space direction="vertical" style="width:100%">
                  <a-input v-model:value="form.email" placeholder="Почта" size="large">
                    <template #prefix>
                      <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
                    </template>
                  </a-input>
                  <a-input v-model:value="form.password" type="password" placeholder="Пароль" size="large">
                    <template #prefix>
                      <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                    </template>
                  </a-input>
                  <a-button
                      type="primary"
                      block
                      html-type="submit"
                      :disabled="form.user === '' || form.password === ''"
                      size="large"
                    >
                      Авторизоваться
                  </a-button>
                </a-space>
            </a-form>
            </div>
          </a-col>
        </a-row>
    </a-layout-content>
  </a-layout>    
  
</template>
<script>
import { defineComponent } from 'vue';
import { mapActions } from 'vuex'

export default defineComponent({
  components: {
  },

  data(){
    return {
      form:{
        email: null,
        password: null,
      }
    }
  },
  
  methods: {
    ...mapActions({
      login: 'auth/login',
    }),
    postLogin(){
      this.login(this.form)
    }
  },
});
</script>
<style scope>
  .login-card {
    background:#fff;
    margin-top: 50px;
    padding: 25px;
  }
  .login-card h1 {
    font-size: 24px;
    margin-bottom: 15px;
    padding-bottom: 15px;
  }
  .form-model {
    margin: 10px 0;
  }
</style>