<template>
    <a-select style="width: 120px" @change="handleChange">
      <a-select-option v-for="city in cities" :key="city.id" :value="key"> 
        {{ city.name }}
      </a-select-option>
    </a-select>
</template>

<script>
import { defineComponent } from 'vue'
import axios from 'axios'

export default defineComponent ({
    mounted() {
           this.getCities() 
    },
    methods: {
       getCities() {
        try {   
            axios.get('api/cities/all')
                 .then(response => ( 
                        this.cities = response.data.data.payload
                    ));
        } catch(e) {
            console.log(e)
        }
       }
    },
    data() {
        return {
            cities: null,
            first: null,
        }
    }
})
</script>
