<template>
  
        <a-form
            ref="formRef"
            :model="formState"
            :rules="rules"
            :label-col="labelCol"
            :wrapper-col="wrapperCol"
        >
       
            <a-form-item ref="email" label="Email" name="email">
                <a-input v-model:value="formState.email" />
            </a-form-item>

            <a-form-item label="Роль" name="role">
                <a-select v-model:value="formState.role" placeholder="выберите роль">
                    <a-select-option v-for="role in formState.roles" :key="role.id" :value="role.name">{{ role.name }}</a-select-option>
                </a-select> 
            </a-form-item>
       

            <a-button type="primary" @click="onSubmit">Создать</a-button>
            <a-button style="margin-left: 10px" @click="resetForm">Сбросить</a-button>

        </a-form>
      
</template>
<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import NavBar from '@/components/partials/NavBar.vue'
import Header from '@/components/partials/Header.vue'
import Footer from '@/components/partials/Footer.vue'
import Users from '@/components/Users.vue'
import axios from 'axios'
import router from '@/router'

export default defineComponent({
  components: {
    NavBar,
    Header,
    Footer,
    Users
  },

  setup() {
    const formRef = ref();
    const formState = reactive({
      email: '',
      role: "",
      roles: undefined,
    });
    const rules = {
      email: [
        {
          required: true,
          message: 'Заполните поле email',
          trigger: 'blur',
        },
        {
          min: 3,
          max: 255,
          message: 'Длинна должна быть от 3 до 255 символов',
          trigger: 'blur',
        },
      ],
      role: [
        {
            required: true,
            message: 'Выберите роль',
        }
      ]
    };

    axios
      .get('admin/users/roles')
      .then(response => (
        formState.roles = response.data
      ))
      .catch(error => {
        console.log(error);
      })  

    const onSubmit = () => {
      formRef.value
        .validate()
        .then(() => {
          let result = toRaw(formState)
          console.log(formState.role)
          axios
            .post('admin/users/create', 
              {
                email: result.email,
                role: result.role,
              })
            .then(response => (
              router.push({name: 'Users'})
            ))
            .catch(error => {
              console.log(error);
            })  
          
        })
        .catch(error => {
          console.log('error', error);
        });
    };

    const resetForm = () => {
      formRef.value.resetFields();
    };

    return {
      formRef,
      labelCol: {
        span: 4,
      },
      wrapperCol: {
        span: 14,
      },
      other: '',
      formState,
      rules,
      onSubmit,
      resetForm,
    };
  },
  
});
</script>