import { createRouter, createWebHistory } from 'vue-router'
import Cities from '../views/CitiesView.vue'
import Letters from '../views/Letters/LettersView.vue'
import LettersCreate from '../views/Letters/LettersCreateView.vue'
import LettersUnpublished from '../views/Letters/LettersUnpublishedView.vue'
import LetterView from '../views/Letters/LetterReviewView.vue'
import LetterEdit from '../views/Letters/LetterEditView.vue'
import Users from '../views/Users/UsersView.vue'
import UserEdit from '../views/Users/UserEditView.vue'
import UserCreate from '../views/Users/UserCreateView.vue'
import Login from '../views/LoginView.vue'
import store from '@/store'

const routes = [
  {
    path: '/cities',
    name: 'Cities',
    component: Cities,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/auth']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  { 
    path: '/', 
    redirect: { name: 'Letters' }
  },
  {
    path: '/letters',
    name: 'Letters',
    component: Letters,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/auth']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/letters/create',
    name: 'LettersCreate',
    component: LettersCreate,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/auth']) {
        return next({
          name: 'Login'
        })
      }
   
      if (store.getters['auth/role'] != 'admin') {
        return next({
          name: 'Letters'
        })
      }

      next()
    }
  },
  {
    path: '/letters/edit/:letterId',
    name: 'LetterEdit',
    component: LetterEdit,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/auth']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/letters/item/:letterId',
    name: 'LetterView',
    component: LetterView,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/auth']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/letters/unpublished',
    name: 'LettersUnpublished',
    component: LettersUnpublished,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/auth']) {
        return next({
          name: 'Login'
        })
      }

      next()
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: Users,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/auth']) {
        return next({
          name: 'Login'
        })
      }
      
      if (store.getters['auth/role'] != 'admin') {
        return next({
          name: 'Letters'
        })
      }

      next()
    }
  },
  {
    path: '/users/edit/:userId',
    name: 'UserEdit',
    component: UserEdit,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/auth']) {
        return next({
          name: 'Login'
        })
      }

      if (store.getters['auth/role'] != 'admin') {
        return next({
          name: 'Letters'
        })
      }

      next()
    }
  },
  {
    path: '/users/create/',
    name: 'UserCreate',
    component: UserCreate,
    beforeEnter: (to, from, next) => {
      if (!store.getters['auth/auth']) {
        return next({
          name: 'Login'
        })
      }

      if (store.getters['auth/role'] != 'admin') {
        return next({
          name: 'Letters'
        })
      }

      next()
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (store.getters['auth/auth']) {
        return next({
          name: 'Letters'
        })
      }

      next()
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
