<template>
    <a-layout-header type="flex" style="background: #2c3e50; color:white; padding: 0">
        <a-row>
        <a-col :span="6"></a-col>
        <a-col :span="12"><h2 style="color:white"> ❤️ Любовные письма ❤️ </h2></a-col>
        <a-col :span="6"><a-button type="primary" @click.prevent="logout"> Выход </a-button></a-col>
        </a-row>
    </a-layout-header>
</template>

<script>
import { defineComponent } from 'vue'
import { mapGetters, mapActions } from 'vuex'

export default defineComponent({
    setup() {
        
    },

    computed: {
        ...mapGetters({
            auth: 'auth/auth',
            user: 'auth/user',
        }),

    },

    methods:{
        ...mapActions({
        logoutAction: 'auth/logout'
        }),

        logout(){
        this.logoutAction().then(()=>{
            this.$router.replace({
            name:'Login'
            })
        })
        }

    },

})
</script>
